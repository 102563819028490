import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { Paper, Typography, Button, IconButton, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Title from "../layout/Title";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';


const columns = [
  { field: "username", headerName: "Usuario", width: 200 },
  { field: "email", headerName: "Correo", width: 300 },
  { field: "roles", headerName: "Rol", width: 130, valueGetter: (params) => {

    let rolLabel = '';
    for (const property in params.row.roles) {
      rolLabel += property
    } 

    return rolLabel;
  }},

  {
    field: "action",
    headerName: "Acciones",
    width: 130,
    renderCell: (params) => {

      const { row } = params;
      const id = row.id;

      const onDelete = async (e) => {
        console.log("Delete row");

        if (!window.confirm('Esta seguro de eliminar el usuario?')) {
          return;
        }

        const API_URL = `users/${id}`;
        try {
          let response = {};
          await row.connection.delete(API_URL, {}, { withCredentials: true });
    
        } catch (err) {
          //setErrMsg("Error al Eliminar la Orden");
        }

      };
      return (
        <>
          <Stack direction="row" spacing={0}>
            <IconButton aria-label="edit" component={Link} to={`/admin/users/${id}`}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => onDelete()}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        </>
      );
    },
  },
];

export default function UserList() {
  const [orders, setOrders] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get("/users", {
          signal: controller.signal,
        });

        const data = response.data;
        //Modify rows here:
        const modifiedData = data.map(element => {
          return { ...element, connection: axiosPrivate };
        });
        isMounted && setOrders(modifiedData);
      } catch (err) {
        console.error(err);
        navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <Paper elevation={6} sx={{ p: 2 }}>
      <Title>Usuarios</Title>

      <Button variant="outlined" size="small" component={Link} to="/admin/users/new" >
        Crear
      </Button>
      <br />
      <br />
      {orders?.length > 0 ? (
        <DataGrid
          rows={orders}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          //checkboxSelection
          autoHeight
          density="compact"
        />
      ) : (
        <Typography>No se encontraron Usuarios</Typography>
      )}
    </Paper>
  );
}
