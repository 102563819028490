import { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation, Link } from "react-router-dom";

import { Paper, Typography, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Title from '../layout/Title';


const columns = [
  { field: 'order_no', headerName: 'Order No', width: 70 },
  { field: 'date_order', headerName: 'Fecha', width: 130 },
  { field: 'business_name', headerName: 'Razón Social', width: 130 },
  { field: 'identification', headerName: 'Identificación', width: 130},
  { field: 'technical_user_id', headerName: 'Técnico', width: 130,
      valueGetter: (params) =>
      `${params.row.technical_user_id.username || ''}`,   
  }
];

export default function LastOrders() {

  const [orders, setOrders] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getOrders = async () => {
      try {
        const response = await axiosPrivate.get("/orders", {
          signal: controller.signal,
        });
        isMounted && setOrders(response.data);
      } catch (err) {
        console.error(err);
        navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getOrders();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (

    <Paper elevation={6} sx={{p: 2, height: "280px" }}>

        <Title>Ordenes Recientes</Title>

        {orders?.length >0
                        ?  <DataGrid
                        rows={orders}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        //checkboxSelection
                        autoHeight
                        density="compact"
                        hideFooter
                        //rowHeight={40}
                        />
                        : <Typography>No se encontraron ordenes</Typography>
        }


       
    
    </Paper>
  );
}
