
import { useRef, useState } from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import HomeIcon from '@mui/icons-material/Home';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from "@mui/material";


import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import SignatureCanvas from 'react-signature-canvas'

import EmailIcon from '@mui/icons-material/Email';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import SaveIcon from '@mui/icons-material/Save';

import useAxiosPrivate from "../hooks/useAxiosPrivate";

import Title from './layout/Title';

import TextField from '@mui/material/TextField';

import './canvas.css';



const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

const API_URL = '/admin/orders';

const OrderNew = () => {

    const axiosPrivate = useAxiosPrivate();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/orders";

    const [errMsg, setErrMsg] = useState('');

    const errRef = useRef();

    const [modalInsert, setModalInsert] = useState(false);

    const openCloseModalInsert = () => {
        setModalInsert(!modalInsert);
    }

    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();

    //console.log(watch("example"));

    
    const onSubmit = async(data) => {
        console.log(data);

        try {
            const response = await axiosPrivate.post(API_URL,
                JSON.stringify(data),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            //const orderId = response?.data?.accessToken;
            //const roles = response?.data?.roles;
            
            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Error al Crear la Orden');
            }
            errRef.current.focus();
        }


    }

    const formatNumber = (inputNumber) => {
        let formatedNumber=(Number(inputNumber)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        let splitArray=formatedNumber.split('.');
        if(splitArray.length>1){
          formatedNumber=splitArray[0];
        }
        return(formatedNumber);
    };

    function ccyFormat(num) {

        let label = '';
        if(num){
            label = `${formatNumber(num)}` ;
        }

    return `${label}`;
    }

  
    // Signature

    const sigCanvasCustomer = useRef({});
    const sigCanvasTechnical = useRef({});
    const [imageCustomerURL, setImageCustomerURL] =  useState('');
    const [imageTechnicalURL, setImageTechnicalURL] =  useState('');

    const clearCanvasCustomer = () => sigCanvasCustomer.current.clear();
    const clearCanvasTechnical = () => sigCanvasTechnical.current.clear();

    const saveSignatureCustomer = () => {

        const base64 = sigCanvasCustomer.current.getTrimmedCanvas().toDataURL("image/png");
        setValue("signature_customer", base64);
        setImageCustomerURL(base64);
    }

    
    const saveSignatureTechnical = () => {
        const base64 = sigCanvasTechnical.current.getTrimmedCanvas().toDataURL("image/png");
        setValue("signature_technical", base64);
        setImageTechnicalURL(base64);
    }

    // Add Products
    const [sectionProducts, setSectionProducts] = useState({
        subtotal: '',
        tax: '',
        total: '',
        products: []
    });
    
    const initProductFields = {ref: '', desc: '', qty: '', unit: '', vat: '', tax: '', price: ''};
    const [product, setProduct] = useState(initProductFields);

    const handleChangeProduct = e => {
        const {name, value} = e.target;
        setProduct( prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const addProduct = () => {
        console.log('add Product');
        //section.products.push(product);

        let unit = product.unit;
        const subtotal = product.unit * product.qty;

        let tax = 0;
        if(product.vat){
            tax = subtotal * (product.vat/100);
        }

        const price = subtotal + tax;

        product.tax = tax;
        product.price = price;

        console.log('product');
        console.log(product);

        sectionProducts.products.push(product);

        calculateTotalProducts();
        
        openCloseModalInsert();

        // Inicializar
        setProduct(initProductFields);
    }

    const calculateTotalProducts = () =>{

        // Total 

        let subtotal = 0;
        let tax = 0;
        let total = 0;

        sectionProducts.products.map((product,index) => {
            subtotal += parseFloat(product.unit)*parseInt(product.qty);
            tax += product.tax;
            total += product.price;
        });

        sectionProducts.subtotal = subtotal;
        sectionProducts.tax = tax;
        sectionProducts.total = total;

        

    }
    //


    const [selectedDate, handleDateChange] = useState(new Date());


  return (

    <Paper elevation={6} sx={{p: 3, mb:3}}>

        <Title>Nueva Orden de Servicio</Title>

        
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

            <br/>
            <form onSubmit={handleSubmit(onSubmit)}>

                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                    <Grid item xs={12}>
                        <Grid item xs={8} sm={6} md={4}>
                            <TextField 
                                type="date" 
                                {...register("date_order", {required: true})}  
                                label="Fecha" 
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                variant="standard"
                                />

                            <small>{errors.date_order?.message}</small>


                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <TextField
                            variant="standard"
                            {...register("business_name", {
                                required: "This is required", 
                                minLength: {
                                    value: 4,
                                    message: "Min length is 4"
                                }
                                })
                            } 
                            label="Nombre o razón social" 
                            fullWidth
                        />

                        
                        <small>{errors.business_name?.message}</small>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField 
                            label="NIT"
                            variant="standard"
                            fullWidth
                            {...register("identification", {
                                required: "This is required", 
                                minLength: {
                                    value: 6,
                                    message: "Min length is 6"
                                }
                                })
                            }
                        />
                        <small>{errors.identification?.message}</small>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField 
                            label="Dirección"
                            variant="standard"
                            fullWidth
                            {...register("address")}
                        />
                        <small>{errors.address?.message}</small>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField 
                            label="Ciudad"
                            variant="standard" 
                            fullWidth
                            {...register("city")}
                        />
                        <small>{errors.city?.message}</small>
                    </Grid>

                    <Grid item xs={12} md={6}>
                    
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <ContactPhoneIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                            <TextField 
                                label="Teléfono y/o Cel"
                                variant="standard" 
                                fullWidth
                                {...register("phone", {required: true, pattern: /^\S+@\S+$/i})}
                            />
                            <small>{errors.phone?.message}</small>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <EmailIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                            <TextField 
                                label="Correo"
                                variant="standard" 
                                fullWidth
                                {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
                            />
                            <small>{errors.email?.message}</small>
                        </Box>
                        
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField 
                            label="Cargo"
                            variant="standard"
                            fullWidth
                            {...register("position")}/>
                        <small>{errors.position?.message}</small>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField 
                            label="Servicio solicitado por"
                            variant="standard"
                            fullWidth
                            {...register("requested_by")}/>
                        <small>{errors.requested_by?.message}</small>
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <TextField type="time"
                            label="Hora de Ingreso"
                            variant="standard"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register("checkin_time")}
                        />
                        <small>{errors.checkin_time?.message}</small>
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <TextField type="time"
                            label="Hora de Salida"
                            variant="standard"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register("checkout_time")}/>
                        <small>{errors.checkout_time?.message}</small>
                    </Grid>

                    <Grid item xs={12}>

                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                            <Grid item xs={6} sm={4}>
                                <FormControlLabel
                                    value="Diagnostico"
                                    control={<Checkbox />}
                                    label="Diagnostico"
                                    labelPlacement="end"
                                    {...register("type_service[]")}
                                />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormControlLabel
                                    value="Garantía pos -venta"
                                    control={<Checkbox />}
                                    label="Garantía pos -venta"
                                    labelPlacement="end"
                                    {...register("type_service[]")}
                                />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormControlLabel
                                    value="Garantía servicio"
                                    control={<Checkbox />}
                                    label="Garantía servicio"
                                    labelPlacement="end"
                                    {...register("type_service[]")}
                                />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormControlLabel
                                    value="Prueba"
                                    control={<Checkbox />}
                                    label="Prueba"
                                    labelPlacement="end"
                                    {...register("type_service[]")}
                                />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormControlLabel
                                    value="Preventivo"
                                    control={<Checkbox />}
                                    label="Preventivo"
                                    labelPlacement="end"
                                    {...register("type_service[]")}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>

                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                            <Grid item xs={6} sm={4}>
                                <TextField 
                                    label="Equipo"
                                    variant="standard"
                                    fullWidth
                                    {...register("equipment")}/>
                                <small>{errors.equipment?.message}</small>
                            </Grid>

                            <Grid item xs={6} sm={4}>
                                <TextField 
                                    label="Modelo"
                                    variant="standard"
                                    fullWidth
                                    {...register("model")}/>
                                <small>{errors.model?.message}</small>
                            </Grid>

                            <Grid item xs={6} sm={4}>
                                <TextField 
                                    label="Serial"
                                    variant="standard"
                                    fullWidth
                                    {...register("series")}/>
                                <small>{errors.series?.message}</small>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                            label="1. Diligenciar en caso de Servicio Técnico o Garantía"
                            variant="standard" 
                            multiline
                            fullWidth
                            rows={4}
                            {...register("description_one")} 
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                            label="2. Aplica solo para Post venta"
                            variant="standard" 
                            multiline
                            fullWidth
                            rows={4}
                            {...register("description_two")} 
                        />

                    </Grid>


                    {/* Products */}
                    <Grid item xs={12}>
                        <div><label htmlFor="description_three">3. Descripción del servicio y repuestos:</label></div>
                        <br/>
                        
                        <TableContainer component={Paper}>
                            <Table aria-label="spanning table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>Ref</TableCell>
                                    <TableCell>Desc</TableCell>
                                    <TableCell align="right">Cant.</TableCell>
                                    <TableCell align="right">V/Unit</TableCell>
                                    <TableCell align="right">IVA</TableCell>
                                    <TableCell align="right">Total</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>

                                {sectionProducts.products?.length ? (
                                    <>
                                    {sectionProducts.products.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {row.ref}
                                                <input type="hidden" {...register("description_three[products]["+index+"][ref]")} value={`${row.ref}`} />
                                            </TableCell>
                                            <TableCell>
                                                {row.desc}
                                                <input type="hidden" {...register("description_three[products]["+index+"][desc]")} value={`${row.desc}`} />
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.qty}
                                                <input type="hidden" {...register("description_three[products]["+index+"][qty]")} value={`${row.qty}`} />
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.unit}
                                                <input type="hidden" {...register("description_three[products]["+index+"][unit]")} value={row.unit} />
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.vat}
                                                <input type="hidden" {...register("description_three[products]["+index+"][vat]")} value={row.vat} />
                                            </TableCell>
                                            <TableCell align="right">
                                                {ccyFormat(row.price)}
                                                <input type="hidden" {...register("description_three[products]["+index+"][tax]")} value={`${row.tax}`} />
                                                <input type="hidden" {...register("description_three[products]["+index+"][price]")} value={`${row.price}`} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </>

                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={5} align="left">
                                            <small>Sin productos</small>
                                        </TableCell>

                                    </TableRow>
                                )}
                                
                                </TableBody>

                                {sectionProducts.products?.length ? (
                                    <>
                                    
                                        <TableFooter sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
                                            <TableRow>
                                                <TableCell rowSpan={3} />
                                                <TableCell rowSpan={3} />
                                                <TableCell rowSpan={3} />
                                                <TableCell colSpan={2} align="right">SUB-TOTAL</TableCell>
                                                <TableCell align="right">
                                                    {ccyFormat(sectionProducts.subtotal)}
                                                    <input type="hidden" {...register("description_three[subtotal]")} value={`${sectionProducts.subtotal}`} />
                                                    </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2} align="right">IVA</TableCell>
                                                <TableCell align="right">
                                                    {ccyFormat(sectionProducts.tax)}
                                                    <input type="hidden" {...register("description_three[tax]")} value={`${sectionProducts.tax}`} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2} align="right">TOTAL</TableCell>
                                                <TableCell align="right">
                                                    {ccyFormat(sectionProducts.total)}
                                                    <input type="hidden" {...register("description_three[total]")} value={`${sectionProducts.total}`} />
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                        
                                    </>
                                ) : (
                                <></>
                                )}
                                
                            </Table>
                        </TableContainer>

                    </Grid>

                    <Grid item xs={12} align="right">
                        <Button onClick={openCloseModalInsert} size="small" variant="outlined">Agregar Item</Button>
                    </Grid>
                    
                    <Grid item xs={12}>

                        <FormControl>
                            <FormLabel sx={{mb: 2}} id="demo-form-control-label-placement">Satisfacción del Servicio</FormLabel>
                            <RadioGroup 
                                row
                                aria-labelledby="demo-form-control-label-placement"
                                name="service_satisfaction"
                                defaultValue="top"
                            >
                                <FormControlLabel control={<Radio color="default" {...register("service_satisfaction")} />} value="1" label="1" labelPlacement="top" />
                                <FormControlLabel control={<Radio color="default" {...register("service_satisfaction")} />} value="2" label="2" labelPlacement="top" />
                                <FormControlLabel control={<Radio color="default" {...register("service_satisfaction")} />} value="3" label="3" labelPlacement="top" />
                                <FormControlLabel control={<Radio color="default" {...register("service_satisfaction")} />} value="4" label="4" labelPlacement="top" />
                                <FormControlLabel control={<Radio color="default" {...register("service_satisfaction")} />} value="5" label="5" labelPlacement="top" />
                            </RadioGroup>
                        </FormControl>

                    </Grid>
                    
                    <Grid item xs={12}>
                        <TextField 
                            label="Observaciones del Cliente"
                            variant="standard" 
                            multiline
                            fullWidth
                            rows={4}
                            {...register("descripcion_customer")} 
                        />
                    </Grid>

                    {/* FirmaS */}
                    <Grid item xs={12} sx={{marginTop: 12}} >


                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                            <Grid item xs={12} md={6}>

                                <span>
                                        
                                    {imageCustomerURL ? (
                                        <img
                                            src={imageCustomerURL}
                                            style={{
                                                display: "block",
                                                margin: "0 auto",
                                                border: "1px solid black",
                                                width: "250px",
                                                marginBottom: "5px"
                                            }}
                                        />
                                    ) : null }
                                    <input type="hidden" value={imageCustomerURL} {...register("signature_customer")}/>
                                    <hr/>
                                    <Popup modal trigger={<Button variant="text">NOMBRE CLIENTE</Button>} closeOnDocumentClick={false} /* position="right center" */>
                                        {close => (
                                            <>
                                                <SignatureCanvas 
                                                    ref={sigCanvasCustomer}
                                                    penColor='black'
                                                    canvasProps={{
                                                        className:"sigCanvas"
                                                    }} 
                                                    fullWidth 
                                                />
                                                <div style={{display:"flex", justifyContent: "space-between"}}>
                                                    <Button variant="outlined" size="small" onClick={saveSignatureCustomer}>Listo!</Button>
                                                    <Button variant="outlined" size="small" onClick={clearCanvasCustomer}>Limpiar</Button>
                                                    <Button variant="outlined" size="small" onClick={close}>Cerrar</Button>
                                                </div>
                                            </>
                                        )
                                        }
                                        
                                    </Popup>
                                </span>

                            </Grid>


                            <Grid item xs={12} md={6}>

                                <span>

                                    {imageTechnicalURL ? (
                                        <img
                                            src={imageTechnicalURL}
                                            style={{
                                                display: "block",
                                                margin: "0 auto",
                                                //border: "1px solid black",
                                                width: "250px",
                                                marginBottom: "5px"
                                            }}
                                        />
                                    ) : null }
                                    <input type="hidden" value={imageTechnicalURL} {...register("signature_technical")}/>
                                    <hr/>
                                    <Popup modal trigger={<Button variant="text">NOMBRE TÉCNICO</Button>} closeOnDocumentClick={false} /* position="right center" */>
                                        {close => (
                                            <>
                                                <SignatureCanvas 
                                                    ref={sigCanvasTechnical}
                                                    penColor='black'
                                                    canvasProps={{
                                                        className:"sigCanvas"
                                                    }} 
                                                    fullWidth 
                                                />
                                                <div style={{display:"flex", justifyContent: "space-between"}}>
                                                    <Button variant="outlined" size="small" onClick={close}>Cerrar</Button>
                                                    <Button variant="outlined" size="small" onClick={saveSignatureTechnical}>Listo!</Button>
                                                    <Button variant="outlined" size="small" onClick={clearCanvasTechnical}>Limpiar</Button>
                                                </div>
                                            </>
                                        )}
                                        
                                    </Popup>
                                </span>

                            </Grid>

                        </Grid>
                    </Grid>
                    

                    <Grid item xs={12} sx={{marginTop: 3}} >
                        <Button type="submit"  variant="contained" endIcon={<SaveIcon/>} style={{width:"100%"}}>
                            Guardar
                        </Button>
                    </Grid>

                </Grid>

            </form>
            
            

            <Modal open={modalInsert} onClose={openCloseModalInsert}>
                <Box sx={styleModal}>
                    <h3>Agregar Servicio y/o Repuesto</h3>
                    <br/>

                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                        <Grid item xs={12}>
                            <TextField
                                name="ref"
                                variant="standard" 
                                onChange={handleChangeProduct}
                                label="Referencia" 
                                fullWidth
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="desc"
                                variant="standard" 
                                onChange={handleChangeProduct}
                                label="Descripción" 
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="qty"
                                variant="standard" 
                                onChange={handleChangeProduct}
                                label="Cantidad" 
                                fullWidth
                                type="number"
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="number"
                                name="unit"
                                variant="standard" 
                                onChange={handleChangeProduct}
                                label="Valor Unitario" 
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="number"
                                name="vat"
                                variant="standard" 
                                onChange={handleChangeProduct}
                                label="IVA(%)" 
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} align="center">
                            <Button variant="contained" onClick={addProduct}>Agregar</Button>
                            &nbsp;&nbsp;
                            <Button variant="contained" onClick={openCloseModalInsert}>Cancelar</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

    </Paper>
  )
}

export default OrderNew