import { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControlLabel from "@mui/material/FormControlLabel";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import FormControl from "@mui/material/FormControl";


import EmailIcon from '@mui/icons-material/Email';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';


export default function CustomerData({register, setValue ,orderData, errors}) {
  console.log('CustomerData');
  console.log(errors);
  const axiosPrivate = useAxiosPrivate();

  // Lista de contratistas
  const [contractors, setContractors] = useState([]);
  const [selectedContractor, setSelectedContractor] = useState("");

  // Obtener la lista de contratistas del backend
  useEffect(() => {
    const fetchContractors = async () => {
      try {
        const response = await axiosPrivate.get("/users/contractors");
        setContractors(Array.isArray(response.data) ? response.data : []); // Asegurar que siempre sea un array
      } catch (err) {
        console.error("Error al obtener contratistas:", err);
        setContractors([]); // Si hay un error, evitar que contractors sea undefined
      }
    };
    fetchContractors();
  }, [axiosPrivate]);

  // Manejar el cambio del contratista seleccionado
  const handleContractorChange = (event) => {
    const value = event.target.value;
    setSelectedContractor(value);
    setValue("shared_with", value || null); // Actualizar el valor en react-hook-form
  };

  useEffect(() => {
    // Inicializar el valor si viene de `orderData` (al editar una orden)
    if (orderData?.shared_with) {
      setSelectedContractor(orderData.shared_with);
      setValue("shared_with", orderData.shared_with);
    } else {
      setSelectedContractor(""); // Si no hay contratista, lo dejamos vacío
      setValue("shared_with", null); // Aseguramos que no sea "" sino null
    }
  }, [orderData, setValue]);

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Datos del Cliente
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={8} sm={18}>
          {contractors.length > 0 && (
            <FormControl variant="standard" sx={{ width: "90%" }}>
              <InputLabel id="compartido_con">Compartido Con</InputLabel>
              <Select
                labelId="compartido_con"
                id="compartido_con"
                value={selectedContractor}
                onChange={handleContractorChange}
                fullWidth
              >
                <MenuItem value="">
                  <em>Seleccione un contratista</em>
                </MenuItem>
                {contractors.map((contractor) => (
                  <MenuItem key={contractor._id} value={contractor._id}>
                    {contractor.username} ({contractor.email})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            required
            type="date"
            label="Fecha" 
            name="date_order"
            {...register("date_order")}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            variant="standard"
          />
          <small>{errors.date_order?.message}</small>
        </Grid>

        <Grid item xs={6} md={3}>
            <TextField type="time"
                required
                label="Hora de Ingreso"
                variant="standard"
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                {...register("checkin_time", {required: true})}
            />
        </Grid>

        <Grid item xs={6} md={3}>
            <TextField 
                required
                type="time"
                label="Hora de Salida"
                variant="standard"
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                {...register("checkout_time", {required: true})}/>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="business_name"
            {...register("business_name", {
              required: "This is required", 
              minLength: {
                  value: 4,
                  message: "Min length is 4"
              }
              })
            } 
            label="Nombre o razón social" 
            fullWidth
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="identification"
            label="NIT"
            fullWidth
            variant="standard"
            {...register("identification", {
              required: "This is required", 
              minLength: {
                  value: 6,
                  message: "Min length is 6"
              }
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="address"
            required
            label="Dirección"
            fullWidth
            variant="standard"
            {...register("address", {required: true})}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="city"
            label="Ciudad"
            required
            fullWidth
            variant="standard"
            {...register("city", {required: true})}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
                    
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <ContactPhoneIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField 
                    label="Teléfono y/o Cel"
                    variant="standard" 
                    fullWidth
                    required
                    name="phone"
                    {...register("phone", {required: true})}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
            </Box>
        </Grid>

        <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <EmailIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField 
                    label="Correo"
                    variant="standard" 
                    fullWidth
                    required
                    {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
                <small>{errors.email?.message}</small>
            </Box>
            
        </Grid>

        <Grid item xs={12} md={6}>
            <TextField 
                label="Cargo"
                variant="standard"
                fullWidth
                required
                {...register("position")}
                InputLabelProps={{
                  shrink: true,
                }}
            />
        </Grid>

        <Grid item xs={12} md={6}>
            <TextField 
                label="Servicio solicitado por"
                variant="standard"
                fullWidth
                required
                {...register("requested_by")}
                InputLabelProps={{
                  shrink: true,
                }}
            />
            <small>{errors.requested_by?.message}</small>
        </Grid>

      </Grid>
    </>
  );
}