import { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableFooter } from "@mui/material";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const DetailProducts = ({ products, register }) => {

  const [modalInsert, setModalInsert] = useState(false);

  const openCloseModalInsert = () => {
    setModalInsert(!modalInsert);
  };

  // Add Products
  const [sectionProducts, setSectionProducts] = useState({
    subtotal: "",
    tax: "",
    total: "",
    products: [],
  });

  const initProductFields = {
    ref: "",
    desc: "",
    qty: "",
    unit: "",
    vat: "",
    tax: "",
    price: "",
  };
  const [product, setProduct] = useState(initProductFields);

  const handleChangeProduct = (e) => {
    const { name, value } = e.target;
    setProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  useEffect(() => {

    if(products !== undefined && products?.subtotal){
      setSectionProducts(products);
    }

  }, [products]);

  const addProduct = () => {
    console.log("add Product");
    //section.products.push(product);

    const subtotal = product.unit * product.qty;

    let tax = 0;
    if (product.vat) {
      tax = subtotal * (product.vat / 100);
    }

    const price = subtotal + tax;

    product.tax = tax;
    product.price = price;

    console.log("product");
    console.log(product);

    sectionProducts.products.push(product);

    calculateTotalProducts();

    openCloseModalInsert();

    // Inicializar
    setProduct(initProductFields);
  };

  const calculateTotalProducts = () => {
    // Total

    let subtotal = 0;
    let tax = 0;
    let total = 0;

    sectionProducts.products.map((product, index) => {
      subtotal += parseFloat(product.unit) * parseInt(product.qty);
      tax += product.tax;
      total += product.price;
    });

    sectionProducts.subtotal = subtotal;
    sectionProducts.tax = tax;
    sectionProducts.total = total;
  };

  const formatNumber = (inputNumber) => {
    let formatedNumber = Number(inputNumber)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    let splitArray = formatedNumber.split(".");
    if (splitArray.length > 1) {
      formatedNumber = splitArray[0];
    }
    return formatedNumber;
  };

  function ccyFormat(num) {
    let label = "";
    if (num) {
      label = `${formatNumber(num)}`;
    }

    return `${label}`;
  }

  return (
    <>
      <div sx={{ mt: 3 }}>
        <label htmlFor="description_three">
          3. Descripción del servicio y repuestos:
        </label>
      </div>
      <br />

      <TableContainer component={Paper} sx={{ "xs": {maxWidth: "350px", overflow: "auto"}}}>
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>Ref</TableCell>
              <TableCell>Desc</TableCell>
              <TableCell align="right">Cant.</TableCell>
              <TableCell align="right">V/Unit</TableCell>
              <TableCell align="right">IVA</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sectionProducts?.products?.length ? (
              <>
                {sectionProducts.products.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {row.ref}
                      <input
                        type="hidden"
                        {...register(
                          "description_three[products][" + index + "][ref]"
                        )}
                        value={`${row.ref}`}
                      />
                    </TableCell>
                    <TableCell>
                      {row.desc}
                      <input
                        type="hidden"
                        {...register(
                          "description_three[products][" + index + "][desc]"
                        )}
                        value={`${row.desc}`}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {row.qty}
                      <input
                        type="hidden"
                        {...register(
                          "description_three[products][" + index + "][qty]"
                        )}
                        value={`${row.qty}`}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {row.unit}
                      <input
                        type="hidden"
                        {...register(
                          "description_three[products][" + index + "][unit]"
                        )}
                        value={row.unit}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {row.vat}
                      <input
                        type="hidden"
                        {...register(
                          "description_three[products][" + index + "][vat]"
                        )}
                        value={row.vat}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {ccyFormat(row.price)}
                      <input
                        type="hidden"
                        {...register(
                          "description_three[products][" + index + "][tax]"
                        )}
                        value={`${row.tax}`}
                      />
                      <input
                        type="hidden"
                        {...register(
                          "description_three[products][" + index + "][price]"
                        )}
                        value={`${row.price}`}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="left">
                  <small>Sin productos</small>
                </TableCell>
              </TableRow>
            )}
          </TableBody>

          {sectionProducts.products?.length ? (
            <>
              <TableFooter sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell rowSpan={3} />
                  <TableCell rowSpan={3} />
                  <TableCell colSpan={2} align="right">
                    SUB-TOTAL
                  </TableCell>
                  <TableCell align="right">
                    {ccyFormat(sectionProducts.subtotal)}
                    <input
                      type="hidden"
                      {...register("description_three[subtotal]")}
                      value={`${sectionProducts.subtotal}`}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} align="right">
                    IVA
                  </TableCell>
                  <TableCell align="right">
                    {ccyFormat(sectionProducts.tax)}
                    <input
                      type="hidden"
                      {...register("description_three[tax]")}
                      value={`${sectionProducts.tax}`}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} align="right">
                    TOTAL
                  </TableCell>
                  <TableCell align="right">
                    {ccyFormat(sectionProducts.total)}
                    <input
                      type="hidden"
                      {...register("description_three[total]")}
                      value={`${sectionProducts.total}`}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </>
          ) : (
            <></>
          )}
        </Table>
      </TableContainer>

      <Grid item xs={12} align="right" sx={{mt: 3}}>
        <Button onClick={openCloseModalInsert} size="small" variant="outlined">
          Agregar Item
        </Button>
      </Grid>
      <Modal open={modalInsert} onClose={openCloseModalInsert}>
        <Box sx={styleModal}>
          <h3>Agregar Servicio y/o Repuesto</h3>
          <br />

          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <TextField
                name="ref"
                variant="standard"
                onChange={handleChangeProduct}
                label="Referencia"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="desc"
                variant="standard"
                onChange={handleChangeProduct}
                label="Descripción"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="qty"
                variant="standard"
                onChange={handleChangeProduct}
                label="Cantidad"
                fullWidth
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="number"
                name="unit"
                variant="standard"
                onChange={handleChangeProduct}
                label="Valor Unitario"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="number"
                name="vat"
                variant="standard"
                onChange={handleChangeProduct}
                label="IVA(%)"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} align="center">
              <Button variant="contained" onClick={addProduct}>
                Agregar
              </Button>
              &nbsp;&nbsp;
              <Button variant="contained" onClick={openCloseModalInsert}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default DetailProducts;
