import { Outlet } from "react-router-dom"

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

const mdTheme = createTheme();

const Layout = () => {
    return (
        <ThemeProvider theme={mdTheme}>
            <CssBaseline />
            <Box sx={{ display: 'flex' }}>

                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
                >
                    <Outlet />
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default Layout
