import Register from './components/Register';
//import Login from './components/Login';
import Home from './components/home/Home';
import Layout from './components/Layout';
import Editor from './components/Editor';
import Admin from './components/Admin';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import Lounge from './components/Lounge';
import LinkPage from './components/LinkPage';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import { Routes, Route } from 'react-router-dom';
import OrderList   from './components/OrderList';
import OrderSingle   from './components/OrderSingle';
import OrderNew   from './components/OrderNew';
import SignIn from './components/login/SignIn';
import Template from './components/layout/Template';
import ServiceOrder from './components/orders/ServiceOrder';
import UserList from './components/users/UserList';
import UserSingle from './components/users/UserSingle';
import SurveyList from './components/surveys/SurveyList';
import SurveySingle from './components/surveys/SurveySingle';
//import * as OrderNew   from './pages/orders/New';
//import * as OrderSingle  from './pages/orders/Single';


/* import LocalizationProvider from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
 */

const ROLES = {
  'Admin': 1950,
  'Support': 1985,
  'User': 2022,
  'Contractor': 2025
}

function App() {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}

        <Route element={<PersistLogin />}>
          <Route path="/" element={<SignIn />} />
        </Route>


        <Route index path="login" element={<SignIn />} />
        <Route path="register" element={<Register />} />
        <Route path="linkpage" element={<LinkPage />} />
        <Route path="unauthorized" element={<Unauthorized />} />

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Support, ROLES.Contractor]} />}>
            
            <Route path="admin" element={<Template />}>

                <Route index element={<Home/>}/>

                <Route path="orders">
                  <Route index element={<OrderList/>}/>
                  {/* <Route path="new" element={<OrderNew/>}/> */}
                  <Route path="new" element={<ServiceOrder/>}/>
                  {/* <Route path=":id" element={<OrderSingle/>}/> */}
                  <Route path=":id" element={<ServiceOrder/>}/>
                </Route>


                <Route path="surveys">
                  <Route index element={<SurveyList/>}/>
                  {/* <Route path="new" element={<OrderNew/>}/> */}
                  <Route path="new" element={<SurveySingle/>}/>
                  {/* <Route path=":id" element={<OrderSingle/>}/> */}
                  <Route path=":id" element={<SurveySingle/>}/>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                  <Route path="users">
                    <Route index element={<UserList/>}/>
                    {/* <Route path="new" element={<OrderNew/>}/> */}
                    <Route path="new" element={<UserSingle/>}/>
                    {/* <Route path=":id" element={<OrderSingle/>}/> */}
                    <Route path=":id" element={<UserSingle/>}/>
                  </Route>
                </Route>

            </Route>
          </Route>



          {/* <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Admin]} />}>
            <Route path="/" element={<Home />} />
          </Route> */}

          {/* <Route element={<RequireAuth allowedRoles={[ROLES.Editor]} />}>
            <Route path="editor" element={<Editor />} />
          </Route> */}

          {/* <Route path="orders">
            <Route index element={<OrderList/>}/>
            <Route path=":id" element={<OrderSingle/>}/>
            <Route path="new" element={<OrderNew/>}/>
          </Route> */}

          {/* <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="admin" element={<Admin />} />
          </Route> */}

          <Route element={<RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />}>
            <Route path="lounge" element={<Lounge />} />
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;