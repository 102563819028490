import React from 'react'
import LastOrders from './LastOrders'
//import DataTable from './DataTable'

const Home = () => {
  return (
    <>
        <LastOrders/>
        {/* <DataTable/> */}
    </>
  )
}

export default Home