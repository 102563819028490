import React, { useState } from "react";

import { Outlet } from "react-router-dom"
import { Box, Stack, Skeleton } from '@mui/material'

const Feed = () => {
    const [loading, setLoading] = useState(true);

    setTimeout(() => {
        setLoading(false);
    }, [1000]);

    return (
        <Box flex={4}>
            
            {loading ? (
                <Stack spacing={1}>
                    <Skeleton variant="text" height={100} />
                    <Skeleton variant="text" height={20} />
                    <Skeleton variant="text" height={20} />
                    <Skeleton variant="rectangular" height={300} />
                </Stack>
            ) : (
                <>
                    <Outlet/>
                </>
            )}

        </Box>
    );
}

export default Feed;
